html,
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root-container {
  height: 100%;
}

body::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 3px;

  @include tablet {
    width: 6px;
  }
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
  visibility: hidden;
}

*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.field {
  @apply mb-2.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
