[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select,
.p-inputtext {
  @apply text-base text-[#495057] bg-white p-3 border-[1px] border-solid border-[#ced4da] rounded-md !important;
}

input:disabled {
  @apply bg-gray-200 !important;
}
