input[type="checkbox"]:checked {
  @apply bg-[#15a3e4] bg-none;
}

input[type="checkbox"]:checked ~ span:first-of-type {
  @apply text-white;
}

input[type="checkbox"]:checked:not([disabled]) ~ span:first-of-type:hover {
  @apply bg-[#15a3e4];
}

input[type="checkbox"]:focus {
  @apply outline-none outline-offset-0 shadow-none;
  --tw-ring-inset: none !important;
  --tw-ring-offset-width: none !important;
  --tw-ring-offset-color: none !important;
  --tw-ring-color: none !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
}
