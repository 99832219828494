.p-calendar-w-btn-right > input {
  border-radius: 6px 0 0 6px !important;
}

button.p-datepicker-trigger {
  @apply bg-[#15a3e4] !important;
}

button.p-datepicker-trigger:hover {
  @apply bg-[#128fc9] !important;
}
