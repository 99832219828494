/* Cutom prime react variables */
/* :root {
    
} */

html {
  font-size: 14px;
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-treeselect:not(.p-disabled).p-focus,
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply shadow-sm shadow-blue-200 ring-0 ring-offset-transparent;
}

.float-label .p-inputwrapper-filled ~ label,
.float-label .p-inputwrapper-focus ~ label,
.float-label input.p-filled ~ label,
.float-label input:focus ~ label,
.float-label textarea.p-filled ~ label,
.float-label textarea:focus ~ label {
  @apply -top-5 left-2 text-xs;
}

.p-component.p-inputtext-sm.p-inputwrapper input,
.p-component.p-inputtext-sm.p-inputwrapper > button.p-button-icon-only {
  line-height: inherit;
  padding: 0.65625rem 0.65625rem;
}

.p-fileupload.p-fileupload-basic.p-component.p-button-sm > .p-button.p-component.p-fileupload-choose {
  line-height: inherit;
  padding: 0.65625rem 0.65625rem;
}

/* tabs element */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  @apply font-medium text-sm;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled) {
  @apply focus:shadow-none;
}

.field > .p-inputswitch {
  @apply h-full;
}

.field > .p-inputswitch > .p-inputswitch-slider {
  @apply my-2;
}

/* menu element */

.p-menu {
  padding-top: 0;
  padding-bottom: 0;
}

.p-menu li {
  border-bottom: 1px solid rgb(246, 246, 246);
}

.p-menu li:last-of-type {
  border-bottom: none;
}
.p-menu li:first-of-type :hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.p-menu li:last-of-type :hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: none;
}

/* button element */

.ring-shadow:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #86EFAC, 0 1px 2px 0 black;
}

.p-button.p-button-outlined.p-button-secondary {
  @apply border border-solid border-gray-300;
}

button.button-default {
  @apply bg-white border-gray-300 text-gray-700 hover:bg-gray-100 ring-shadow !important;
}
button.button-primary {
  @apply bg-primary text-white border-primary hover:bg-green-500 ring-shadow !important;
}
button.p-button-text {
  @apply p-1.5 focus:ring-1 !important;
}

/* Tooltip */
.p-tooltip > span {
  @apply p-0 m-0 space-y-0;
}

.p-tooltip-text {
  @apply p-2 text-sm !important;
}

.p-skeleton-circle {
  @apply rounded-full;
}
