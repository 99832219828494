input[type="radio"]:checked {
  @apply bg-[#128fc9];
}

input[type="radio"]:checked ~ span:first-of-type {
  @apply bg-[#128fc9];
  @apply text-white;
}

input[type="radio"]:checked:not([disabled]) ~ span:first-of-type:hover {
  @apply bg-[#15a3e4];
}

input[type="radio"]:focus {
  @apply bg-[#128fc9] outline-none outline-offset-0 shadow-none;
  --tw-ring-inset: none !important;
  --tw-ring-offset-width: none !important;
  --tw-ring-offset-color: none !important;
  --tw-ring-color: none !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
}

input[type="radio"]:checked:hover,
input[type="radio"]:checked:focus {
  @apply bg-[#128fc9];
}
